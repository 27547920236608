import React from "react"

import SectionTitle from "../SectionTitle"
import { Container, StepCard } from "../UI"

const StepSection = ({ donationSectionTitle, donationStep }) => {
  return (
    <section className="mt-[128px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      <Container>
        <SectionTitle>{donationSectionTitle}</SectionTitle>
        <div className="gap-x-6 md:grid md:grid-cols-2 lg:grid-cols-3">
          {React.Children.toArray(
            donationStep.map((step, index) => (
              <StepCard
                step={index + 1}
                heading={step.heading}
                description={step.description}
              />
            ))
          )}
        </div>
      </Container>
    </section>
  )
}

export default StepSection
