import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import { isParagraph } from "datocms-structured-text-utils"

import { LayoutContainer } from "../UI"

const DynamicGivingStepsSection = ({ data }) => {
  return (
    <section className="mt-[-170px] mb-[128px] md:mt-[140px] md:mb-[140px] lg:mt-[151px] lg:mb-[151px]">
      {React.Children.toArray(
        data.map(d => (
          <OrangSection
            id={d.id}
            title={d.title}
            subTitle={d.subtitle}
            description={d.description}
            image={d.image}
            isOrange={d.orangeSection}
          />
        ))
      )}
    </section>
  )
}

export default DynamicGivingStepsSection

const OrangSection = ({
  id,
  title,
  subTitle,
  description,
  image,
  isOrange,
}) => {
  return (
    <div
      className={`overflow-hidden font-spartan ${
        isOrange ? "bg-yellow-gradient" : "bg-white bg-opacity-70"
      } `}
    >
      <div className="mx-auto max-w-screen-2xl md:flex">
        <LayoutContainer
          className={`grid place-items-center py-12 md:w-1/2 ${
            isOrange ? "order-1" : "order-2"
          }`}
        >
          <div
            className={`mx-auto md:max-w-[420px] ${
              isOrange ? "text-white" : "text-black"
            }`}
          >
            <h2 className="mb-[10px] text-[12px] font-bold uppercase leading-[19px] tracking-[5px]">
              {title}
            </h2>
            <h3 className="mb-[10px] text-2xl font-bold leading-[39px]">
              {subTitle}
            </h3>
            <StructuredText
              data={description}
              customRules={[
                renderRule(isParagraph, ({ children, key }) => {
                  if (children[0].length === 0) return <br />
                  return (
                    <p
                      key={key}
                      className="font-spartan text-base leading-[25.92px]"
                    >
                      {children}
                    </p>
                  )
                }),
              ]}
            />
          </div>
        </LayoutContainer>
        <div
          className={`grid place-items-center bg-[#FAFAFA] md:w-1/2 md:bg-transparent ${
            isOrange ? "order-2" : "order-1"
          }`}
        >
          <div className="max-w-[320px] py-6 md:py-[85px]">
            <GatsbyImage image={image.gatsbyImageData} alt={image.alt} />
          </div>
        </div>
      </div>
    </div>
  )
}
