import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { Main } from "../components/UI"
import Renderer from "../components/DynamicPageRenderer"
import Seo from "../components/SEO"

const DynamicPages = ({
  data: { datoCmsTestPageWithLayout: data },
  location,
}) => {
  const canonicalUrl = `https://thetoucan.app${location.pathname}`
  return (
    <Layout isHomePage>
      <Seo
        title={data.pageName}
        noIndex={data.noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main className="">
        {React.Children.toArray(data.modules.map(el => <Renderer el={el} />))}
      </Main>
    </Layout>
  )
}

export const query = graphql`
  query DatoCmsPageWithLayout($slug: String!) {
    datoCmsTestPageWithLayout(slug: { eq: $slug }) {
      pageName
      slug
      noIndex
      modules {
        ... on DatoCmsTestStepCard {
          id
          internal {
            type
          }
          stepCards {
            heading
            description
          }
        }
        ... on DatoCmsTestReviewSlide {
          id
          internal {
            type
          }
          sectionTitle
          reviewSlides {
            author
            location
            review
          }
        }
        ... on DatoCmsHomepageHero {
          internal {
            type
          }
          title
          description {
            value
          }
          backgroundImage {
            alt
            gatsbyImageData
          }
          heroPhones {
            alt
            gatsbyImageData
          }
          showDownloadAppButton
          iosDownloadLink
          androidDownloadLink
        }
        ... on DatoCmsAllYourGiving {
          internal {
            type
          }
          topImage {
            gatsbyImageData
            alt
          }
          title
          description {
            value
          }
          image {
            gatsbyImageData(imgixParams: { w: "338", h: "199" })
            alt
          }
        }
        ... on DatoCmsGivingStep {
          id
          internal {
            type
          }
          allGivingStep {
            title
            subtitle
            description {
              value
            }
            image {
              alt
              gatsbyImageData
            }
            orangeSection
          }
        }
        ... on DatoCmsInstagramblock {
          id
          internal {
            type
          }
          showInstagramModal
        }
        ... on DatoCmsMessage {
          internal {
            type
          }
          id
          title
          message {
            value
          }
          fullWidth
        }
        ... on DatoCmsDynamicform {
          internal {
            type
          }
          id
          title
          subtitle
          name
          action
          method
          showform
        }
      }
    }
  }
`

export default DynamicPages
