import React from "react"

import StepSection from "../StepSection"
import SliderSection from "../SliderSection"
import Hero from "../Hero"
import AllYourGiving from "../AllYourGivingPage"
import DynamicGivingStepsSection from "../GivingStepsSection/DynamicGivingStepsSection"
import InstagramNodes from "../InstagramNodes"
import GivingNature from "../GivingNature"
// import DynamicPageForm from "../DynamicPageForm/DynamicPageForm"

const ErrorMessage = msg => {
  return (
    <p style={{ color: "red", fontSize: "30px" }}>
      &lt;- It's dead, Omar. {msg}
    </p>
  )
}

const Renderer = ({ el }) => {
  switch (el.internal?.type) {
    case "DatoCmsHomepageHero":
      return (
        <Hero
          title={el.title}
          showDownloadAppButton={el.showDownloadAppButton}
          iosDownloadLink={el.iosDownloadLink}
          androidDownloadLink={el.androidDownloadLink}
          heroPhones={el.heroPhones}
          backgroundImage={el.backgroundImage}
          description={el.description}
          isDynamic={true}
        />
      )
    case "DatoCmsTestStepCard":
      return (
        <StepSection
          donationSectionTitle="Doing good is about to feel a lot better."
          donationStep={el.stepCards}
        />
      )
    case "DatoCmsTestReviewSlide":
      return (
        <SliderSection
          reviewSectionTitle={el.sectionTitle || "What the all say..."}
          reviewSlide={el.reviewSlides}
        />
      )
    case "DatoCmsAllYourGiving":
      return (
        <AllYourGiving
          topImage={el.topImage}
          title={el.title}
          image={el.image}
          description={el.description}
        />
      )
    case "DatoCmsGivingStep":
      return <DynamicGivingStepsSection data={el.allGivingStep} />
    case "DatoCmsInstagramblock":
      if (!el.showInstagramModal) return null
      return <InstagramNodes />
    case "DatoCmsMessage":
      return (
        <GivingNature
          donationSectionTitle={el.title}
          message={el.message}
          fullWidth={el.fullWidth}
        />
      )
    case "DatoCmsDynamicform":
      return null // <DynamicPageForm data={el} />
    default:
      return ErrorMessage(
        "I don't know how to render the modular block type \"" +
          el.internal.type +
          '"'
      )
  }
}

export default Renderer
